import React from 'react';
// import Picture from '../../public/images/profile_picture.JPG'; // Make sure to adjust the path based on your project structure

const ProfileImage = () => {
  return (
    <div>
      <img src="/images/profile_picture.JPG" alt="" style={{ maxWidth: '200px', height: 'auto' }} />
    </div>
  );
}

export default ProfileImage;
