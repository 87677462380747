import React from "react";
import Data from '../Data.json';
import Modal from './Modal';

const Projects = () => {
    return (
      <section className="flex flex-col w-auto mb-5">
        <h3 className="mt-0 lg:mt-4 mb-5 px-1 lg:px-5 font-bold text-xl">RECENT PROJECTS</h3>
        <div className="flex flex-row snap-mandatory snap-x overflow-x-auto">
  
          {Data.projects.map((project) => (
            <div key={project.id} className="project-card bg-gray-50 flex flex-col justify-between" id={"project-card-" + project.id}>
              <div className="mb-0">
                <div className="bg-white rounded-xl h-[200px] mb-5" id={"project-hero-" + project.id}>
  
                </div>
                {/* <p className="text-xl mb-1 text-black font-bold">{project.title}</p> */}
                <Modal data={project} id={"modal-" + project.id}/>
                <p className="mb-2 text-black">{project.description}</p>
  
                <div className="mt-2 flex flex-row flex-wrap">
                  {project.languages.map((language, index) => (
                      <span key={index} className="text-xs border rounded-full px-2 py-1 mr-1 mb-2">{language}</span>
                  ))}
                </div>
                
              </div>
            </div>
          ))}
        
        </div>
      </section>
    )
}

export default Projects;