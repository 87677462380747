import React from 'react';
import Data from '../Data.json';


const PersonalText = () => {
  return (
    <>
      <p className="">{Data.basic_info.bio}</p>
    </>
  )
}

export default PersonalText;